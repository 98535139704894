<template>
  <div class="standardTaskList">
      <div class="title-bg">
        <div class="title-info title-desc flex">
            <div class="siteHeader">
              <div class="siteInfoDiv">
                <h5>{{siteName}}</h5>
                <p>编号：{{areaNo}}</p>
                <p>所属部门：{{siteDepartment}}</p>
              </div>
            </div>
        </div>
    </div>
    <div class="taskList">
      <div class="taskList-item flex" v-for="(item, index) in taskList" :key="index"  @click="gotoFinished(item)">
        <div>{{item.name}}</div>
        <div class="button" :class="{'button-finished': item.hasFinished}">{{item.hasFinished ? '已完成' : '去完成'}}</div>
      </div>
    </div>
    <div class="nextBtn">
      <div class="btn-active" :class="{'disableFlag': disableFlag}" v-if="this.$route.query.detail != '1'" @click="nextBtn">提交</div>
    </div>

  </div>
</template>

<script>
import HeaderSiteInfo from "../../../components/siteInfo/headerSiteInfo";
import { getSiteByStationNo, getMyTask, getSubTaskList, saveMyTask } from '@/utils/api'
import common from '@/utils/common'
import {appAction, uwStatisticAction} from "../../../utils/util";
import {AUTH_SUBMIT, STANDARD_TASK_FINISHED} from "../../../utils/constantNum";
export default {
  components: {
    HeaderSiteInfo,
  },
  data () {
    return {
      siteName: null,
      areaNo: null,
      siteDepartment: null,
      taskList: null,
      siteInfo: null,
      disableFlag: true
    }
  },
  watch:{
    taskList:{
      handler(val){
        let flag = false
        for(let obj of val){
          if(!obj.hasFinished){
            flag = true
            return
          }
        }
        this.disableFlag = flag
      },
      deep: true
    }
  },
  mounted () {
    this.getsiteInfo()
    this.togetSubTaskList()
    uwStatisticAction('/task/standardizationTask/standardTaskList','标准化子任务列表')
  },
  methods: {
    nextBtn() {
      if(this.disableFlag) return
      saveMyTask({
        'taskNo': this.$route.query.taskNo,
        'hasSubmit': true
      }).then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            if (common.getItem('jumpFrom') != undefined ) {
              appAction(STANDARD_TASK_FINISHED)
            }
            this.$router.push({ name: 'myTask'})
          }
        }else{
          this.$toast(res.data.businessMessage)
        }
      })
    },
    gotoFinished(item){
      let code = item.code
      this.$router.push({ name: 'standardizationTask', query:{code: code, taskNo: this.$route.query.taskNo, stationNo: this.$route.query.stationNo,detail: this.$route.query.detail}})
    },
    togetSubTaskList(){
      getSubTaskList({ taskNo: this.$route.query.taskNo}).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.taskList = res.data.data
          }
        }
      })
    },
    getsiteInfo () {
      getSiteByStationNo({
        stationNo: this.$route.query.stationNo
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.siteName = res.data.data.stationName
            this.areaNo = res.data.data.areaNo
            this.siteDepartment = res.data.data.deptName
            common.setItem('masterNo', res.data.data.uniqueNo)
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.standardTaskList {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  background: #F7F8F9;
  .flex{
    display: flex;
  }

  .siteInfo{
    background:#fff;
    height: 2.68rem;
    padding: 0.24rem 0 0.36rem;
  }
  .taskList{
    background: #fff;
    margin-top: 1.64rem;
  }
  .title-bg {
    height: 2.5rem;
    background: #3f7c53;
    position: relative;
    img {
      width: 6.9rem;
    }
    .title-info {
      position: absolute;
      width: 6.9rem;
      height: 2.14rem;
      border-radius: 0.16rem;
      background: #fff;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      padding: 0.38rem 0.3rem;
      align-items: center;
      .siteInfoDiv{
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        h5{
          font-size:15px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:bold;
          color: #333333;
        }
        p{
          font-size:13px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color: #999999;
          margin-top: 4px;
        }
      }
    }
    .header-icon{
      width: 1.36rem;
      height: 1.36rem;
    }
    .left64{
        margin-left: 0.64rem !important;
      }
    .title-info__item{
      margin-left: 0.32rem;
      div {
        &:nth-child(1) {
          color: #999;
          font-size: 0.24rem;
        }
        &:nth-child(2){
          font-size: 0.3rem;
          font-weight: bold;
          margin-top: 0.16rem;
        }
      }
    }
  }
  .taskList-item{
    height: 0.95rem;
    border-bottom: 0.01rem solid #F7F8F9;
    padding: 0 0.3rem;
    font-size: 0.3rem;
    color: #333;
    justify-content: space-between;
    align-items: center;
    .button{
      text-align: center;
      width: 1.2rem;
      height: 0.5rem;
      line-height: 0.5rem;
      font-size: 0.24rem;
      border-radius: 0.25rem;
      background: #3F7C53;
      color: #fff;
      border:0;
      font-weight: normal;
    }
    .button-finished{
      background: #fff;
      color: #3F7C53;
      border: 0.02rem solid #3F7C53;
    }
  }
  .nextBtn {
    width: 100%;
    padding: 0.24rem;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .disableFlag{
    opacity: 0.5 !important;
  }
  .btn-active {
    height: 0.98rem;
    line-height: 0.98rem;
    text-align: center;
    background: #3f7c53;
    border-radius: 0.08rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    opacity: 1;
  }
}
</style>
